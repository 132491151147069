<!-- 媒体报道 -->
<template>
    <div >
      <div class="header">
        <div>
          <h3>{{text17}}</h3>
        </div>
      </div>
      <div class="content">
        <div class="content_nav">
          <router-link to="/New/News">{{text1}}</router-link>
          <router-link to="/New/Media" class="line_red">{{text2}}</router-link>
        </div>
        <div class="content_top">
          <ul class="list">
            <li>
              <div class="li_box">
                <h3>{{text3}}</h3>
                <p>
                  {{text4}}
                </p>
                <div>
                  <p>2021 . 12 . 14</p>
                  <a href="javascript:;">{{text18}}</a>
                </div>
              </div>
              <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/xinwen/list_1.png" alt="" />
            </li>
            <li>
              <div class="li_box">
                <h3>
                  {{text5}}
                </h3>
                <p>
                  {{text6}}
                </p>
                <div>
                  <p>2021 . 12 . 01</p>
                  <a href="javascript:;">{{text18}}</a>
                </div>
              </div>
              <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/xinwen/list_2.png" alt="" />
            </li>
            <li>
              <div class="li_box">
                <h3>{{text7}}</h3>
                <p>
                  {{text8}}
                </p>
                <div>
                  <p>2021 . 11 . 05</p>
                  <a href="javascript:;">{{text18}}</a>
                </div>
              </div>
              <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/xinwen/list_3.png" alt="" />
            </li>

            <li>
              <div class="li_box">
                <h3>{{text9}}</h3>
                <p>
                  {{text10}}
                </p>
                <div>
                  <p>2021 . 11 . 03</p>
                  <a href="javascript:;">{{text18}}</a>
                </div>
              </div>
              <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/xinwen/list_4.png" alt="" />
            </li>
            <li>
              <div class="li_box">
                <h3>
                  {{text11}}
                </h3>
                <p>
                  {{text12}}
                </p>
                <div>
                  <p>2021 . 08 . 18</p>
                  <a href="javascript:;">{{text18}}</a>
                </div>
              </div>
              <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/xinwen/list_5.png" alt="" />
            </li>
            <li>
              <div class="li_box">
                <h3>{{text13}}</h3>
                <p>
                  {{text14}}
                </p>
                <div>
                  <p>2021 . 04 . 23</p>
                  <a href="javascript:;">{{text18}}</a>
                </div>
              </div>
              <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/xinwen/list_6.png" alt="" />
            </li>
            <li>
              <div class="li_box">
                <h3>
                  {{text15}}
                </h3>
                <p>
                  {{text16}}
                </p>
                <div>
                  <p>2021 . 01 . 19</p>
                  <router-link to="/New/NewList">{{text18}}</router-link>
                </div>
              </div>
              <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/xinwen/list_5.png" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
import { mapState } from "vuex";
export default {
  data() {
    switch(language){
      case "cn":
        return{
          text1: "新闻中心",
          text2: "媒体报道",
          text3: "靳照宇博士应邀参加全球生物医药创新先锋中国峰会2021",
          text4: "2021年12月10日，明济生物CEO靳照宇博士应邀参加全球生物医药创新先锋中国峰会2021，并发表“AI在大分子药物开发的应用”主题演讲。",
          text5: "侯兴华博士代表明济生物参加2021太美医疗科技药物警戒数字生态大会",
          text6: "共创·数字心生l药物警戒数字生态会议，于11月26日在上海隆重召开，会议主要讨论当下“药物警戒行业热点，从业人员的关注焦点和工作痛点”共话未来药物警戒行业新生态，新模式。明济生物首席医学官……",
          text7: "第五届中国生物医药创新合作大会成功落幕",
          text8: "第五届中国生物医药创新合作大会于2021年11月4日-5日在广州举行。会议主题包括“抗体药物开发与工程大会”“基因和细胞创新疗法大会”“创新药物及临床试验大会”“小分子创新药开发与合作大会”四大模……",
          text9: "第四届抗体药物开发暨生物工艺技术论坛成功落幕",
          text10: "第四届抗体药物开发暨生物工艺技术论坛于2021 年 10 月 26-27日在上海圣诺亚皇冠假日酒店举行。 本次技术论坛近700位专业人士参与.参会人员主要来自于治疗性抗体及生物制药研究的工艺开发与生产单……",
          text11: "明济生物与亘喜生物达成合作，将针对CLDN18.2阳性实体瘤开发新型免……",
          text12: "明济生物制药（北京）有限公司今天宣布与亘喜生物科技集团（纳斯达克股票代码：GRCL;简称“亘喜生物”）正式签署一项独家授权协议，双方将聚焦Claudin18.2（“CLDN18.2”）靶点，通过基因工程技……",
          text13: "上海分部正式启用",
          text14: "愿明济生物通过持续不懈的努力，成功研发出具有临床应用价值的创新药物，惠及百姓，同时给投资人带来丰厚的回报，为中国成为生物制药的强国作出自己的贡献",
          text15: "明济生物宣布完成3亿元人民币B轮融资，全力打造创新药物开发平台",
          text16: "3月12日，明济生物正式宣布完成B轮融资。本轮融资总规模3亿人民币，由中金资本旗下中金启德基金领投，现有投资方礼来亚洲基金继续追加投资，同时国药资本、德屹资本及和玉资本（MSA Capital）……",
          text17: "新闻中心",
          text18: "详情",
        }
      case "en":
        return{
          text1: "Company news",
          text2: "media coverage",
          text3: "Dr. Jin Zhaoyu was invited to attend the global biomedical innovation pioneer China Summit 2021",
          text4: "On December 10, 2021, Dr. Jin Zhaoyu, CEO of Mingji biology, was invited to attend the global biomedical innovation pioneer China Summit 2021 and delivered a keynote speech on 'the application of AI in macromolecular drug development'.",
          text5: "Dr. Hou Xinghua attended the 2021 Taimei medical technology pharmacovigilance digital ecology Conference on behalf of Mingji biology",
          text6: "Co creation · digital psychogenic l pharmacovigilance digital ecology conference was grandly held in Shanghai on November 26. The conference mainly discussed the current 'hot spots in the pharmacovigilance industry, the focus of attention and work pain points of employees' and talked about the new ecology and new model of the pharmacovigilance industry in the future. Chief medical officer of Mingji Biology......Co creation · digital psychogenic l pharmacovigilance digital ecology conference was grandly held in Shanghai on November 26. The conference mainly discussed the current 'hot spots in the pharmacovigilance industry, the focus of attention and work pain points of employees' and talked about the new ecology and new model of the pharmacovigilance industry in the future. Chief medical officer of Mingji Biology......",
          text7: "The 4th antibody drug development and Biotechnology Forum ended successfully",
          text8: "The Fifth China biomedical innovation cooperation conference was held in Guangzhou from November 4 to 5, 2021. The themes of the conference include 'antibody drug development and Engineering Conference', 'gene and cell innovative therapy conference', 'innovative drugs and clinical trials conference' and 'small molecule innovative drug development and cooperation conference'......",
          text9: "The 5th China biomedical innovation cooperation conference was successfully concluded",
          text10: "The fourth antibody drug development and Biotechnology Forum was held on October 26-27, 2021 It was held at Crowne Plaza Shanghai St. Noah. Nearly 700 professionals participated in this technical forum Participants mainly came from the process development and production list of therapeutic antibody and biopharmaceutical research......",
          text11: "Mingji biology and Genxi biology have reached cooperation and will target cldn18 2. Development of new immune system for positive solid tumors......",
          text12: "Mingji biopharmaceutical (Beijing) Co., Ltd. today announced its cooperation with Genxi biotechnology group (NASDAQ: grcl; Genxi Biology (hereinafter referred to as 'Genxi biology') has officially signed an exclusive license agreement, and both parties will focus on claudin 18.2 ('cldn18.2') target, through genetic engineering technology......",
          text13: "Shanghai Branch officially opened",
          text14: "May Mingji biology, through its continuous and unremitting efforts, successfully develop innovative drugs with clinical application value,benefit the people and provide benefits to investors at the same timeIt will bring rich returns and make its own contribution to China's becoming a powerful biopharmaceutical country",
          text15: "Mingji biology announced that it had completed a round B financing of 300 million yuan and made every effort to build an innovative drug development platform",
          text16: "On March 12, Mingji biology officially announced the completion of round B financing. The total scale of this round of financing is 300 million yuan, led by CICC Qide fund under CICC capital,The existing investors Lilai Asia Fund continued to make additional investment, while Sinopharm capital, Deyi capital and Heyu capital (MSACapital）……",
          text17: "News Center",
          text18: "Details",
        }
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
};
</script>

<style lang="less" scoped>
.header{
    width: 100%;
    height: 400px;
    background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/xinwen/header.png") no-repeat;
    background-size: 100% 100%;
    div{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    h3{
      position: relative;
      font-size: 50px;
      color: #fff;
      padding-top: 20%;
    }
    h3::after{
      position: absolute;
      top: 110%;
      left: 0%;
      content: "";
      width: 50px;
      height: 2px;
      background-color: #fff;
    }
  }
}
.content{
    width: 100%;
    .content_nav{
        height: 130px;
        text-align: center;
        line-height: 130px;
        background-color: rgba(204, 204, 204, 0.2);
        a{
            position: relative;
            height: 130px;
            color: #333;
            font-size: 22px;
            margin-right: 80px;
            font-weight: 600;
            opacity: 0.7;
            -webkit-tap-highlight-color: transparent;
        }
        :nth-child(2){
            color: #C00000 ;
            padding-bottom: 50px;
        }
        .line_red::after{
            position: absolute;
            top: 100%;
            left: 0%;
            content: "";
            width: 79px;
            height: 2px;
            opacity: 0.6;
            background-color: #C00000 ;
        }
    }
    .content_top{
        width: 100%;
        .list{
            width: 100%;
            max-width: 1200px;
            margin: 150px auto;
            li{
                margin-bottom: 52px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                img{
                  height: 188px;
                }
                .li_box{
                    width: 700px;
                    h3{
                        color: #4A4A4A;
                        font-size: 22px;
                        margin-top: 10px;
                    }
                    p{
                        margin-top: 24px;
                        font-size: 16px;
                        color: #999999;
                        line-height: 32px;
                    }
                    div{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        a{
                            height: 22px;
                            color: #203864;
                            font-weight: bold;
                            padding-right: 30px;
                            background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/xinwen/xq.png") no-repeat;
                            background-position: right;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 750px){
    .header{
        height: 150px;
        div{
          h3{
            font-size: 24px;
            margin-left: 10%;
            font-weight: normal;
            padding-top: 20%;
          }
          h3::after{
            width: 30px;
          }
        }
    }
    .content{
        .content_nav{
            height: 70px;
            line-height: 70px;
            display: flex;
            justify-content: space-between;
            a{
                width: 50%;
                height: 70px;
                margin-right: 0px;
            }
            :nth-child(1){
                padding-bottom: 0px;
            }
            .line_red::after{
                top: 57%;
                left: 30%;
            }
        }
        .content_top{
            .list{
                margin: 0px auto;
                li{
                    margin-bottom: 0px;
                    padding: 10px;
                    flex-direction: column;
                    .li_box{
                        width: 90vw;
                        h3{
                          margin-top: 26px;
                        }
                        p{
                            margin-top: 10px;
                            line-height: 26px;
                        }
                    }
                    img{
                      display: none;
                    }
                }
            }
        }
    }
}
</style>